import axios from "axios";

// export const toastConf = {
//     position: "top-right",
//     autoClose: 3000,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     pauseOnFocusLoss: false,
//     hideProgressBar: true,
//     className: "my-toast",
//     theme: "dark",
//     transition: Flip,
// };

export const addContact = async (data) => {
    try {
        const response = await axios.post("/api/contact/", data, {
            headers: { 'Content-Type': 'application/json', }
        });
        if (response.status === 201) {
            console.log("addContact response: ", response.data);
            return response;
        } else {
            console.log("addContact response: ", response);
            return response.error;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}

