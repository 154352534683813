import { logo_hor } from "assets";
import React, { useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

const MyNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  // const param2 = params.length > 1 ? params[1] : "";

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const isLargeScreen = useMediaQuery({
  //   query: "(min-width: 992px) and (max-width: 1300px)",
  // });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [expanded, setExpanded] = useState(false);

  const handleNavigate = (path) => {
    setExpanded(false);
    navigate(path);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      // variant="white"
      fixed="top"
      // className="bg-body-tertiary shadow"
      className="bg-light shadow py-1 px-0"
      // style={{ minHeight: 80 }}
      // style={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}
    >
      <Container className="">
        <div className="d-flex align-items-start w-100 position-relative py-0">
          <Navbar.Brand
            href="/"
            className="d-flex justify-content-center justify-content-lg-start w-100 align-items-center me-0 p-0"
          >
            <div className="">
              <img
                src={logo_hor}
                // width="30"
                // height="30"
                alt="Astromatrix"
                className="d-inline-block align-top img-fluid"
                style={{ height: 76 }}
              />
            </div>
            {/* <div>
              <h3
                className="mb-0 text-secondary fw-bold"
                style={{ letterSpacing: 2.4 }}
              >
                <span style={{ fontSize: "1.7em" }}>A</span>STROMATRIX
              </h3>
              {!large && expanded && <Underline className="mx-auto mt-1" />}
            </div> */}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="position-absolute end-0 focus-none p-1 border-0 mt-3"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? (
              <FaTimes size={24} className="text-secondary" />
            ) : (
              <FaBars size={24} className="text-secondary" />
            )}
          </Navbar.Toggle>
        </div>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        {/* <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="border-0"
          onClick={() => setExpanded(expanded ? false : true)}
        >
          {expanded ? (
            <FaTimes size={24} className="text-secondary" />
          ) : (
            <FaBars size={24} className="text-secondary" />
          )}
        </Navbar.Toggle> */}

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="bg-light pb-4 pb-lg-0 text-center"
        >
          <Nav className="ms-auto">
            <Nav.Link
              // href=""
              className={`mx-1 ${param1 === "" ? "text-primary fw-bold" : ""}`}
              onClick={() => handleNavigate("/")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              // href=""
              className={`mx-1 ${
                param1 === "about-us" ? "text-primary fw-bold" : ""
              }`}
              style={{ whiteSpace: "nowrap" }}
              onClick={() => handleNavigate("/about-us")}
            >
              About Us
            </Nav.Link>
            <Nav.Link
              // href=""
              className={`mx-1 ${
                param1 === "services" ? "text-primary fw-bold" : ""
              }`}
              style={{ whiteSpace: "nowrap" }}
              onClick={() => handleNavigate("/services")}
            >
              Services
            </Nav.Link>
            {/* <Nav.Link
              // href=""
              className={`mx-1 ${ param1 === "contact-us" ? "text-primary fw-bold" : "" }`}
              style={{ whiteSpace: "nowrap" }}
              onClick={() => handleNavigate("/contact-us")}
            >
              Contact Us
            </Nav.Link> */}
            {/* <NavDropdown title="Services" className="mx-1">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2"> Another action </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            </NavDropdown> */}
            <div className="d-flex justify-content-center align-items-center mt-2 mt-lg-0">
              <Button
                className="d-flex justify-content-center align-items-center ms-lg-2 text-white"
                style={{ minWidth: 128, borderRadius: 40, height: 36 }}
                onClick={() => handleNavigate("/contact-us")}
              >
                <span
                  className="fw-500"
                  // className={ param1 === "contact-us" ? "text-secondary fw-bold" : "fw-500" }
                >
                  Contact Us
                </span>
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

// const NavLink = ({ className, title, route }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const params = location.pathname.split("/").filter((item) => item !== "");
//   const param1 = params.length > 0 ? params[0] : "";

//   const [hover, setHover] = useState(null);

//   return (
//     <Nav.Link
//       className={`py-2 ${param1 === route ? "border-primary" : ""} ${
//         hover || hover === "" ? hover === route ? "text-primary" : "text-black" : param1 === route ? "text-primary" : "text-black"
//       } ${className}`}
//       onClick={() => { navigate(`/${route}`); }}
//       onMouseEnter={() => setHover(route)}
//       onMouseLeave={() => setHover(null)}
//       // style={{ color: hover || hover === "" ? hover === route ? "blue" : "black" : param1 === route ? "blue" : "black", }}
//     >
//       {title}
//     </Nav.Link>
//   );
// };

export default MyNavbar;
