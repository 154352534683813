import { engg_team, surveillance, target } from "assets";
import { Underline } from "components/Common/Underline";
import JoinUsCTA from "components/Home/JoinUsCTA";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const About = () => {
  return (
    <Container fluid className="px-0">
      <Container className="px-2 px-lg-4 py-4 py-lg-5">
        <div className="mb-4">
          <h2 className="text-secondary text-center fw-bold my-4">About Us</h2>
          {/* <Underline className="mx-auto mb-4" /> */}
          <div className="mx-auto text-center mb-4" style={{ maxWidth: 800 }}>
            <h4 className="roboto text-dark fw-400" style={{ lineHeight: 1.4 }}>
              With a team of skilled experts and a commitment to excellence, we
              work closely with our clients to understand their challenges and
              goals, providing tailored solutions that enhance efficiency,
              security, and scalability. Our mission is to empower businesses to
              unlock their full potential through cutting-edge technology,
              ensuring they stay ahead in today's competitive environment.
            </h4>
          </div>
          <Row className="py-3">
            <Col lg={5} className="ms-lg-auto me-lg-3 mb-3">
              <div
                className="bg-white border p-4 shadow h-100 mx-2"
                style={{ borderRadius: 16 }}
              >
                <div className="px-3 mb-4">
                  <div className="text-center mb-4">
                    <img
                      src={target}
                      alt=""
                      className="img-fluid"
                      style={{ height: 140 }}
                    />
                  </div>
                  <h3 className="fw-bold text-center text-secondary">
                    Mission
                  </h3>
                  <Underline className="mx-auto mb-3" />
                  <p className="fw-normal roboto text-center mb-3">
                    Our mission is to empower businesses by delivering
                    innovative, reliable, and customized IT solutions that drive
                    efficiency, growth, and digital transformation.
                  </p>
                  <p className="fw-normal roboto text-center">
                    We are committed to providing exceptional service through
                    cutting-edge technology, fostering long-term partnerships,
                    and helping businesses navigate the evolving digital
                    landscape.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={5} className="me-lg-auto ms-lg-3 mb-3">
              <div
                className="bg-white border p-4 shadow h-100 mx-2"
                style={{ borderRadius: 16 }}
              >
                <div className="px-3 mb-4">
                  <div className="text-center mb-4">
                    <img
                      src={surveillance}
                      alt=""
                      className="img-fluid"
                      style={{ height: 140 }}
                    />
                  </div>
                  <h3 className="fw-bold text-center text-secondary">Vision</h3>
                  <Underline className="mx-auto mb-3" />
                  <p className="fw-normal roboto text-center mb-3">
                    Our vision is to become a leading global provider of IT
                    services, known for excellence in innovation, client
                    satisfaction, and technological advancement.
                  </p>
                  <p className="fw-normal roboto text-center">
                    We aim to shape the future of businesses by providing
                    transformative solutions that not only meet today's
                    challenges but also anticipate the needs of tomorrow's
                    digital economy.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      {/* <div
      // style={{
      //   backgroundImage: `url(${hands_on_keyboard})`,
      //   height: 200,
      //   backgroundSize: "100% 100%",
      //   // backgroundSize: "cover",
      //   backgroundAttachment: "fixed",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat",
      // }}
      >
        <img src={hands_on_keyboard} alt="" className="img-fluid w-100" />
      </div> */}
      <Container className="px-4 pb-5">
        <div className="text-center" style={{ marginBottom: -60, zIndex: 0 }}>
          <img
            src={engg_team}
            alt=""
            className="img-fluid"
            style={{ height: 240 }}
          />
        </div>
        <JoinUsCTA />
      </Container>
    </Container>
  );
};

export default About;
