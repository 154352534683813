import React from "react";

export const Underline = ({ width = 60, height = 3, className, style }) => {
  return (
    <div
      className={`bg-primary mb-3 ${className}`}
      style={{ width: width ?? 60, height: height ?? 3, ...style }}
    />
  );
};
