import ContactForm from "components/Contact/ContactForm";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { contact_us } from "assets";
import { Underline } from "components/Common/Underline";

const Contact = () => {
  return (
    <Container fluid className="px-0">
      {/* <div className="position-relative mb-5">
        <div>
          <img
            src={laptop}
            alt=""
            className="img-fluid w-100"
            style={{ height: 300 }}
          />
        </div>
        <div
          className="position-absolute top-0 w-100"
          style={{ background: "rgba(0, 0, 0, 0.7)", height: 300 }}
        >
        </div>
      </div> */}
      <Container className="px-2 px-lg-4 py-4 py-lg-5 mb-4">
        <div className="text-center my-4 mb-lg-5">
          <h3 className="text-secondary fw-bold mb-3">
            Let us help you transform your business with innovative IT
            solutions.
          </h3>
          <h3 className="text-secondary fw-bold mb-3">
            Contact us today to learn more about how we can support your growth.
          </h3>
        </div>
        <Row>
          <Col lg={7} xl={6} className="me-auto mb-3">
            <ContactDetails />
          </Col>
          <Col lg={5} xl={5} className="me-auto mb-3">
            <div
              className="bg-white border p-4 shadow"
              style={{ borderRadius: 16 }}
            >
              <ContactForm />
            </div>
          </Col>
        </Row>
      </Container>

      <div className="bg-white py-1" />
      <div className="bg-light">
        <iframe
          title="location_map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.635830029741!2d73.74575731122499!3d18.590449866962132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9e55175009f%3A0xb92f8442017bd6c!2sSuratwala%20Mark%20Plazzo!5e0!3m2!1sen!2sin!4v1727288113058!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </Container>
  );
};

const ContactDetails = () => {
  return (
    <div className="w-100 ps-lg-5">
      {/* <h2 className="text-secondary mb-4">Get in Touch</h2> */}
      <div className="text-center mb-4">
        <img
          src={contact_us}
          alt=""
          className="img-fluid"
          style={{ height: 140 }}
        />
      </div>
      <Row>
        <Col md={6} className="d-flex">
          <div
            className="bg-white shadow border p-4 mb-4 w-100"
            style={{ borderRadius: 16 }}
          >
            <div>
              <h4 className="text-secondary fw-700">Mail us</h4>
              <Underline width={60} className="mb-3" />
            </div>
            <div className="d-flex align-items-start mb-3">
              <IoMdMail className="text-dark me-2 me-xl-3" size={24} />
              <p
                className="text-dark fw-400"
                style={{ wordBreak: "break-all" }}
              >
                hr@astromatrixsol.com
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} className="d-flex">
          <div
            className="bg-white shadow border p-4 mb-4 w-100"
            style={{ borderRadius: 16 }}
          >
            <div>
              <h4 className="text-secondary fw-700">Call us</h4>
              <Underline width={60} className="mb-3" />
            </div>
            <div className="d-flex align-items-center mb-3">
              <IoMdCall className="text-dark me-2 me-xl-3" size={24} />
              <p className="text-dark fw-400">+91 8208111343</p>
            </div>
            <div className="d-flex align-items-center">
              <IoMdCall className="text-dark me-2 me-xl-3" size={24} />
              <p className="text-dark fw-400">+91 7298333300</p>
            </div>
          </div>
        </Col>
        <Col lg={12} className="d-flex w-100">
          <div
            className="bg-white shadow border p-4 mb-4 w-100"
            style={{ borderRadius: 16 }}
          >
            <div>
              <h4 className="text-secondary fw-700">Address</h4>
              <Underline width={60} className="mb-3" />
            </div>
            <div className="d-flex text-dark mb-2">
              <FaLocationDot className="text-dark me-3" size={24} />
              <address className="text-dark fw-400">
                805A ,Suratwala Mark Plazzo, <br />
                Hinjawadi Rd, Shankar Kalat Nagar, <br />
                Wakad, Pimpri-Chinchwad, Maharashtra <br />
                411057.
              </address>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
