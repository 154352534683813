import { maintenance, programming } from "assets";
import { Underline } from "components/Common/Underline";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SiInertia } from "react-icons/si";

const softwareDevelopmentServices = [
  {
    title: "Custom Software Development",
    description:
      "Tailor-made applications that align with your specific business needs.",
  },
  {
    title: "Enterprise Solutions",
    description:
      "Robust and scalable enterprise software for complex organizational requirements.",
  },
  {
    title: "Mobile App Development",
    description:
      "Cross-platform and native mobile apps that engage users and enhance accessibility.",
  },
  {
    title: "Web Application Development",
    description:
      "Secure, user-friendly, and performance-driven web applications.",
  },
  {
    title: "Cloud-based Solutions",
    description:
      "Optimizing cloud infrastructure to offer flexibility, security, and efficiency.",
  },
  {
    title: "API Development and Integration",
    description:
      "Seamlessly connect your existing systems with third-party services for better workflow management.",
  },
];

const itSupportServices = [
  {
    title: "Helpdesk Support",
    description: "Fast, responsive support to resolve your IT challenges.",
  },
  {
    title: "Remote & Onsite Support",
    description:
      "From troubleshooting to system upgrades, we provide both remote and onsite assistance.",
  },
  {
    title: "IT Infrastructure Management",
    description:
      "Proactive monitoring and management of your hardware, network, and systems.",
  },
  {
    title: "Cloud Services Support",
    description:
      "Ensuring your cloud environments are optimized for performance, security, and cost-effectiveness.",
  },
  {
    title: "Cybersecurity",
    description:
      "Safeguarding your business with tailored security solutions, from threat detection to incident response.",
  },
  {
    title: "Backup & Disaster Recovery",
    description:
      "Protecting your critical data and ensuring business continuity through robust backup and recovery plans.",
  },
];

const ServiceItem = ({ title, description }) => {
  return (
    <div
      className="d-flex text-start bg-light border shadow-sm p-3 h-100"
      style={{ borderRadius: 12 }}
    >
      <SiInertia className="text-primary me-3 mt-1" size={20} />
      <div>
        <h4 className="text-secondary fw-600">{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Services = () => {
  return (
    <Container className="px-2 px-lg-4 py-4 py-lg-5">
      <div className="mb-4">
        <h2 className="text-secondary text-center fw-bold my-4">
          Our Services
        </h2>
        {/* <Underline className="mx-auto mb-4" /> */}
        <div className="mx-auto text-center mb-4" style={{ maxWidth: 800 }}>
          <h4 className="roboto text-dark fw-400" style={{ lineHeight: 1.4 }}>
            We offer a comprehensive range of services designed to meet the
            unique needs of businesses across industries. <br /> From custom
            software development to robust IT support, our solutions are
            tailored to drive efficiency, scalability, and growth.
          </h4>
        </div>
        <Row className="flex-wrap py-3">
          <Col lg={6} className="mb-3">
            <div
              className="bg-white border p-4 shadow h-100 mx-2"
              style={{ borderRadius: 16 }}
            >
              <div className="text-center my-4">
                <img
                  src={programming}
                  alt=""
                  className="img-fluid"
                  style={{ height: 140 }}
                />
              </div>
              <div className="px-3 mb-4">
                <h3 className="fw-bold text-center text-secondary">
                  Software Development
                </h3>
                <Underline className="mx-auto mb-4" />
                <p className="fw-normal roboto text-center">
                  Our custom software development services focus on creating
                  scalable, efficient, and innovative solutions that enhance
                  your business performance. <br /> We work with a wide range of
                  industries, delivering software that is designed to improve
                  processes, boost productivity, and drive growth.
                </p>
              </div>
              <Row className="flex-wrap g-3 mb-4">
                {softwareDevelopmentServices.map((item, index) => {
                  return (
                    <Col key={index} lg={12} className="flex-grow">
                      <ServiceItem
                        key={index}
                        title={item.title}
                        description={item?.description}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mb-3">
            <div
              className="bg-white border p-4 shadow h-100 mx-2"
              style={{ borderRadius: 16 }}
            >
              <div className="text-center my-4">
                <img
                  src={maintenance}
                  alt=""
                  className="img-fluid"
                  style={{ height: 140 }}
                />
              </div>
              <div className="px-3 mb-4">
                <h3 className="fw-bold text-center text-secondary">
                  IT Support
                </h3>
                <Underline className="mx-auto mb-4" />
                <p className="fw-normal roboto text-center">
                  We provide reliable and comprehensive IT support services
                  designed to ensure your technology infrastructure runs
                  smoothly, reducing downtime and increasing efficiency. <br />{" "}
                  Our dedicated team is available 24/7 to handle any technical
                  issues, allowing you to focus on growing your business.
                </p>
              </div>
              <Row className="flex-wrap g-3 mb-4">
                {itSupportServices.map((item, index) => {
                  return (
                    <Col key={index} lg={12} className="flex-grow">
                      <ServiceItem
                        key={index}
                        title={item.title}
                        description={item?.description}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Services;
