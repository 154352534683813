import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import GLOBE from "vanta/dist/vanta.globe.min";

const VantaBackground = ({ children, className = "", style = {} }) => {
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        GLOBE({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          // color: 0xbe104d,
          // color: 0x0179fe,
          // color: 0x3292fc,
          color: large ? 0x006bb3 : 0x3292fc,
          color2: large ? 0xe3e3e3 : 0x01161e,
          backgroundColor: 0x01161e,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect, large]);

  return (
    <div ref={myRef} className={className} style={{ height: "94vh", ...style }}>
      {children}
    </div>
  );
};

export default VantaBackground;
