import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MyNavbar from "./components/Navbar/MyNavbar";
import Home from "pages/Home/Home";
import About from "pages/About/About";
import Services from "pages/Services/Services";
import Contact from "pages/Contact/Contact";
import Footer from "components/Footer/Footer";
import PageNotFound from "pages/Error/PageNotFound";

const Routers = () => {
  return (
    <BrowserRouter>
      <Container fluid className="px-0">
        <MyNavbar />
        <main>
          <div
            className="bg-light"
            style={{ marginTop: 60, minHeight: "63vh" }}
          >
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="about-us" element={<About />} />
              <Route path="services" element={<Services />} />
              <Route path="contact-us" element={<Contact />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </Container>
    </BrowserRouter>
  );
};

export default Routers;
