import './App.css';
import './FontStyle.scss';
import './App.scss';
import Routers from './Routers.jsx'
import { ToastContainer } from 'react-bootstrap';
// import { toastConf } from './utilities/Notify.jsx';

function App() {

  // setDefaultBreakpoints([
  //   { xsmall: 0 }, // all mobile devices
  //   { small: 576 }, // mobile devices (not sure which one's this big)
  //   { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  //   { large: 992 }, // smaller laptops
  //   { xlarge: 1200 }, // laptops and desktops
  //   { xxlarge: 1400 }, // laptops and desktops
  // ]);

  return (
    <div>
      {/* <BreakpointProvider>
        <Provider store={store}> */}
      <ToastContainer />
      <Routers />
      {/* </Provider>
      </BreakpointProvider> */}
    </div>

  );
}

export default App;
