import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const JoinUsCTA = () => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-secondary p-4 text-center border mx-auto position-relative"
      style={{ borderRadius: 16, maxWidth: 1280 }}
    >
      <div className="my-2">
        <h2 className="mb-4 fw-bold text-white">Join Us Today</h2>
        <div className="mx-auto" style={{ maxWidth: 1080 }}>
          <p className="text-white mb-4">
            Become a part of the digital revolution with AstroMatrix Solutions.
            <br /> Whether you're a growing business or an established
            enterprise, our innovative IT solutions are designed to accelerate
            your success. <br /> Let's work together to unlock new possibilities
            and drive your business forward.
          </p>
          <Button
            className="bg-light text-dark fw-bold contact-us-cta"
            style={{ height: 44, width: 180, borderRadius: 40 }}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JoinUsCTA;
