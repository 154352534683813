import { logo_hor } from "assets";
import { Underline } from "components/Common/Underline";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { IoMdCall, IoMdMail } from "react-icons/io";

const fbLink = "";
const instaLink = "";
const linkedinLink = "";

const Footer = () => {
  return (
    <footer>
      <Container fluid className="px-0 bg-dark text-white">
        <Container className="px-4 pt-5 pb-4">
          <Row>
            <Col md={12} lg={4} className="mb-4">
              <div className="mb-2" style={{ marginLeft: -12 }}>
                <img
                  src={logo_hor}
                  // width="30"
                  // height="30"
                  alt="Astromatrix"
                  className="d-inline-block align-top img-fluid"
                  style={{ height: 76 }}
                />
              </div>
              {/* <h3
                className="text-white fw-bold mb-4"
                style={{ letterSpacing: 1.4 }}
              >
                <span style={{ fontSize: "1.5em" }}>A</span>
                STROMATRIX
              </h3> */}
              <div className="d-flex">
                {/* <FaLocationDot className="text-light me-2" size={24} /> */}
                <address className="text-light">
                  805A ,Suratwala Mark Plazzo, <br />
                  Hinjawadi Rd, Shankar Kalat Nagar, <br />
                  Wakad, Pimpri-Chinchwad, Maharashtra <br />
                  411057.
                </address>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div>
                <div className="mb-3">
                  <h4 className="text-white mt-2">Contact Us</h4>
                  <Underline />
                </div>
                <p className="text-light mb-2">
                  <IoMdMail className="text-light me-2" size={24} />
                  hr@astromatrixsol.com
                </p>
                <p className="text-light mb-2">
                  <IoMdCall className="text-light me-2" size={24} />
                  +91 8208111343
                </p>
                <p className="text-light mb-2">
                  <IoMdCall className="text-light me-2" size={24} />
                  +91 7298333300
                </p>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className="mb-3">
                <h4 className="text-white mt-2">Follow us</h4>
                <Underline />
              </div>
              <div>
                {/* <FaLinkedin className="me-3" size={24} color="#0A66C2" />
                <FaFacebook className="me-3" size={24} color="#1877F2" />
                <FaInstagram className="me-3" size={24} color="#D95656" /> */}
                <a href={fbLink} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="text-light me-3" size={24} />
                </a>
                <a href={instaLink} target="_blank" rel="noopener noreferrer">
                  <FaFacebook className="text-light me-3" size={24} />
                </a>
                <a
                  href={linkedinLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram className="text-light me-3" size={24} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="mb-0" />
        <Container className="px-2 px-lg-4 py-2 text-light">
          Copyright © 2024 AstroMatrix Solutions LLP
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;
