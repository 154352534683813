import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import * as authActions from "actions/authActions";
import notify from "utilities/Notify";

const defaultFormData = {
  name: "",
  email: "",
  mobile: "",
  subject: "",
  message: "",
};

const ContactForm = () => {
  const [formData, setFormData] = useState(defaultFormData);

  // Handle form change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    for (let [key, value] of data.entries()) {
      console.log(`${key}: ${value}`);
    }
    const response = await authActions.addContact();
    if (response) {
      notify("Message send successfully", "success");
      setFormData(defaultFormData);
    } else {
      notify("Error while sending messsage", "error");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="text-dark">Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
          style={{ height: 40 }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label className="text-dark">Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Enter your email"
          value={formData.email}
          onChange={handleChange}
          style={{ height: 40 }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formMobile">
        <Form.Label className="text-dark">Mobile</Form.Label>
        <Form.Control
          type="mobile"
          name="mobile"
          placeholder="Enter your mobile"
          value={formData.mobile}
          onChange={handleChange}
          style={{ height: 40 }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formSubject">
        <Form.Label className="text-dark">Subject</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          placeholder="Enter your subject"
          value={formData.subject}
          onChange={handleChange}
          style={{ height: 40 }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formMessage">
        <Form.Label className="text-dark">Message</Form.Label>
        <Form.Control
          as="textarea"
          type="text"
          name="message"
          rows={4}
          placeholder="Enter your message"
          value={formData.message}
          onChange={handleChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        className="text-white fw-bold w-100"
        style={{ height: 40 }}
      >
        Submit
      </Button>
    </Form>
  );
};

export default ContactForm;
