import { it_services, services } from "assets";
import { Underline } from "components/Common/Underline";
import JoinUsCTA from "components/Home/JoinUsCTA";
import VantaBackground from "components/Home/VantaBg";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const whyChooseUsPoints = [
  {
    id: 1,
    image: "",
    title: "Expertise",
    subtitle:
      "A team of highly skilled professionals with deep technical knowledge.",
  },
  {
    id: 2,
    image: "",
    title: "Customization",
    subtitle:
      "Tailored solutions that meet the specific needs of your business.",
  },
  {
    id: 3,
    image: "",
    title: "Reliability",
    subtitle:
      "Round-the-clock support to ensure uninterrupted business operations.",
  },
  {
    id: 4,
    image: "",
    title: "Innovation",
    subtitle:
      "Leveraging the latest technologies to keep your business ahead of the curve.",
  },
  {
    id: 5,
    image: "",
    title: "Security",
    subtitle: "A strong focus on safeguarding your data and systems.",
  },
];

const Home = () => {
  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <Container fluid className="px-0">
      <VantaBackground className="position-relative">
        <div
          className="w-100 h-100 position-absolute top-0 "
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            backgroundSize: "100% 100%",
            // backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container
            className="d-flex justify-content-start align-items-center px-2 px-lg-4 py-5 h-100"
            style={{ minHeight: 500 }}
          >
            <div className="mb-4" style={{ maxWidth: 760 }}>
              <h1
                className="text-white fw-bold mb-2"
                style={{ lineHeight: 1.4 }}
              >
                Innovating <span className="text-white">IT Solutions</span>{" "}
                <br /> for the Future
              </h1>
              <Underline width={120} height={4} className="mb-5 mb-lg-5" />
              <div
              // className=" mb-4 mb-lg-5 p-3"
              // style={{ background: "rgba(0, 0, 0, 0.2)" }}
              >
                <h4
                  className={`roboto text-light ${large ? "fw-400" : "fw-500"}`}
                  style={{ lineHeight: 1.4, letterSpacing: 1.2 }}
                >
                  We are driven by a passion for technology and innovation.{" "}
                  <br /> As a forward-thinking IT company, we specialize in
                  delivering customized software development and IT support
                  services that are designed to meet the unique needs of
                  businesses in a rapidly evolving digital landscape.
                </h4>
              </div>
            </div>
          </Container>
        </div>
      </VantaBackground>

      <section className="">
        <Container className="mx-auto px-2 px-lg-4 py-4 py-lg-5 border-bottom">
          <Row className="align-items-start mx-auto my-5">
            <Col lg={5} className="ms-lg-auto">
              <div
                className="w-100 overflow-hidden"
                style={{ borderRadius: 16 }}
              >
                <img src={it_services} alt="" className="img-fluid w-100" />
              </div>
            </Col>
            <Col lg={5} className="ms-lg-4 me-lg-auto py-4">
              <h2
                className="text-dark text-start fw-bold mb-4"
                style={{ lineHeight: 1.4 }}
              >
                {/* We specialize in delivering cutting-edge IT solutions tailored to meet the unique needs of your business. */}
                {/* "Your Technology Partner in Digital Transformation" */}
                {/* "Driving Growth Through Cutting-Edge Solutions" */}
                {/* "Crafting the Future of IT for Your Business" */}
                {/* Innovating IT Solutions for the Future */}
                Empowering Businesses <br /> with Tailored IT Solutions
              </h2>
              <h5
                className="text-dark roboto text-start fw-400 mb-4"
                style={{ lineHeight: 1.4 }}
              >
                {/* With a team of seasoned experts and a passion for technology, we are dedicated to empowering businesses to thrive in the digital age through our bespoke software development and comprehensive IT support services. */}
                With a team of experienced professionals and an unwavering
                passion for technology, AstroMatrix Solutions is committed to
                empowering businesses to succeed in the digital age.
              </h5>
              <h5
                className="text-dark roboto text-start fw-400 mb-4"
                style={{ lineHeight: 1.2 }}
              >
                We specialize in crafting bespoke software solutions tailored to
                your unique needs, ensuring that your business stays ahead of
                the competition. Our comprehensive IT support services provide a
                solid foundation, allowing your organization to scale
                efficiently, adapt seamlessly, and maintain optimal performance.
              </h5>
              <Button
                className="bg-primary text-white fw-bold site-btn mt-2"
                style={{ height: 44, width: 180, borderRadius: 40 }}
                onClick={() => navigate("/services")}
              >
                Explore Services
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white">
        <Container className="px-2 px-lg-4 py-4 py-lg-5">
          <div className="my-4  my-lg-5">
            <h2 className="mb-5 text-center fw-bold text-secondary">
              Why Choose Us?
            </h2>
            <Row xs={1} sm={2} md={3} xl={5}>
              {whyChooseUsPoints.map((item, index) => {
                return (
                  <Col xs={12} sm={6} key={index} className="mb-3 mb-lg-4">
                    <div
                      className="bg-light shadow border border-primary p-4 w-100 text-center h-100"
                      style={{ borderRadius: 16 }}
                    >
                      <h4 className="fw-bold text-secondary">{item.title}</h4>
                      <Underline className="mx-auto" />
                      <p>{item?.subtitle}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </section>

      {medium && (
        <div
          style={{
            backgroundImage: `url(${services})`,
            height: 280,
            backgroundSize: "100% 100%",
            // backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <img src={hands_on_keyboard} alt="" className="img-fluid w-100" /> */}
        </div>
      )}
      {/* <div className="bg-white py-5"></div> */}
      {/* <section className="bg-light shadow">
        <Container className="px-2 px-lg-4 py-4 py-lg-5">
          <div className="my-4  my-lg-5">
            <h2 className="mb-5 text-center fw-bold text-secondary">
              Why Choose Us
            </h2>
            <div>
              {whyChooseUsPoints.map((item, index) => {
                return <div key={index}></div>;
              })}
            </div>
          </div>
        </Container>
      </section> */}
      <section className="bg-light border-top py-5">
        <Container className="px-2 px-lg-4 py-4 pt-lg-5">
          <JoinUsCTA />
        </Container>
      </section>
    </Container>
  );
};

export default Home;
