import { page_not_found } from "assets";
import React from "react";
import { Container } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center px-4 pt-5 mt-5">
      {/* <p className="mb-4 fw-bold text-secondary" style={{ fontSize: 200 }}>
        404
      </p> */}
      <div className="w-100 text-center mt-5">
        <div className="mb-4  mb-lg-5">
          <img
            src={page_not_found}
            alt=""
            className="img-fluid"
            style={{ maxWidth: 500 }}
          />
        </div>
        <h2>Page not found</h2>
      </div>
    </Container>
  );
};

export default PageNotFound;
